<template>
  <CustomBottomSheet
    :refName="'InstallmentScheduleTimesFilter'"
    size="xl"
    :headerText="$t('Reports.installmentScheduleTimes')"
    :headerIcon="icon"
    @opened="getDialogs()"
  >
    <div class="row">
      <CustomSelectBox
        :className="'col-md-12'"
        :id="'installmentBatchTypeToken'"
        :value="installments.filterData.installmentBatchTypeToken"
        v-on:changeValue="
          installments.filterData.installmentBatchTypeToken = $event
        "
        :options="installmentBatchTypeTokenOptions"
        :title="$t('InstallmentBatchTypes.select')"
        :imgName="'installmentBatchTypes.svg'"
      />
      <BaseTree
        :hierarchySystemTypeFilterToken="hierarchySystemTypeFilterToken"
        :treeModel="getTreeModel"
        v-on:changeValue="
          baseTreeChanged($event);
          getEducationalGroupsDialog();
        "
        :showParent="true"
        :filterParentDirect="true"
      />
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`educationalGroupToken`"
        :value="installments.filterData.educationalGroupToken"
        :options="educationalGroupTokenOptions"
        v-on:changeValue="
          installments.filterData.educationalGroupToken = $event
        "
        :title="$t('EducationalGroups.select')"
        :imgName="'EducationalGroups.svg'"
      />
      <CustomInput
        :className="'col-md-12'"
        :id="`sendTo`"
        :value="installments.filterData.sendTo"
        v-on:changeValue="installments.filterData.sendTo = $event"
        :title="$t('Reports.sendTo')"
        :imgName="'email.svg'"
      />

      <DateTimePicker
        class="col-md-6"
        id="dateTimeStartSearch"
        type="dateTime"
        :value="installments.filterData.dateTimeStartSearch"
        v-on:changeValue="
          installments.filterData.dateTimeStartSearch = $event.dateTime
        "
        :title="$t('Reports.dateTimeFrom')"
        :language="language"
      />
      <DateTimePicker
        class="col-md-6"
        id="dateTimeEndSearch"
        type="dateTime"
        :value="installments.filterData.dateTimeEndSearch"
        v-on:changeValue="
          installments.filterData.dateTimeEndSearch = $event.dateTime
        "
        :title="$t('Reports.dateTimeTo')"
        :language="language"
      />
    </div>

    <div class="form-actions">
      <div class="icon-submit" @click.prevent="getReport">
        <img
          src="@/assets/images/check-icon.svg"
          :title="$t('Reports.print')"
        />
      </div>
      <div
        @click.prevent="closeBottomSheet('InstallmentScheduleTimesFilter')"
        class="icon-cancel"
      >
        <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "@/components/general/CustomBottomSheet.vue";
import BaseTree from "@/components/general/BaseTree.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import DateTimePicker from "@/components/general/DateTimePicker.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import generalMixin from "@/utils/generalMixin";
import icon from "@/assets/images/installments.svg";
import { STATUS } from "@/utils/constants";
import { SYSTEM_TYPE } from "@/utils/constantLists";
import Installments from "@/models/joiningApplications/installments/Installments";
import apiInstallment from "@/api/joiningApplications/installments";
import { getLanguage, viewFileFromServer } from "@/utils/functions";
import {
  getInstallmentBatchTypesDialog,
  getEducationalGroupsDialog,
} from "@/utils/dialogsOfApi";

export default {
  mixins: [generalMixin],
  data() {
    return {
      isLoading: false,
      language: getLanguage(),
      installments: new Installments(),
      icon,
      installmentBatchTypeTokenOptions: [],
      educationalGroupTokenOptions: [],
    };
  },
  components: {
    CustomBottomSheet,
    BaseTree,
    CustomInput,
    DateTimePicker,
    CustomSelectBox,
  },
  computed: {
    hierarchySystemTypeFilterToken() {
      return SYSTEM_TYPE.EducationalActivity;
    },
    getTreeModel() {
      let treeModelObject = {
        systemComponentHierarchyToken:
          this.installments.filterData.systemComponentHierarchyToken,
        hierarchyTitle: this.$t(
          "SystemComponentsHierarchies.selectEducationalActivity"
        ),
        systemComponentToken: this.installments.filterData.systemComponentToken,
        componentTitle: this.$t("SystemComponents.selectEducationalActivity"),
        parentToken: this.installments.filterData.educationalCategoryToken,
        parentTitle: this.$t("EducationalCategories.select"),
        parentImgName: "EducationalCategories.svg",
      };
      return treeModelObject;
    },
  },

  methods: {
    getDialogs() {
      this.getInstallmentBatchTypesDialog();
      this.getEducationalGroupsDialog();
    },

    baseTreeChanged(data) {
      this.installments.filterData.systemComponentHierarchyToken =
        data.hierarchyToken;
      this.installments.filterData.systemComponentToken = data.componentToken;
      this.installments.filterData.educationalCategoryToken = data.parentToken;
      this.installments.filterData.educationalCategoryTokens = [
        data.parentToken,
      ];
    },

    async getInstallmentBatchTypesDialog() {
      this.$emit("isLoading", true);
      this.installmentBatchTypeTokenOptions =
        await getInstallmentBatchTypesDialog();
      this.$emit("isLoading", false);
    },
    async getEducationalGroupsDialog() {
      this.$emit("isLoading", true);
      this.educationalGroupTokenOptions = [];
      let params = {
        educationalCategoryToken:
          this.installments.filterData.educationalCategoryToken,
      };
      this.educationalGroupTokenOptions = await getEducationalGroupsDialog(
        params
      );
      this.$emit("isLoading", false);
    },
    async getReport() {
      this.$emit("isLoading", true);
      try {
        const response = await apiInstallment.getReport(
          this.installments.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.closeBottomSheet("InstallmentScheduleTimesFilter");
          viewFileFromServer(response.data.reportPath);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.$emit("isLoading", false);
    },
    async created() {
      await this.getInstallmentBatchTypesDialog();
    },
  },
};
</script>
